import * as SC from './Footer.styles'

const Footer = () => {
  return (
    <SC.Footer>
      <div>
        <div className="company-logo">
          <SC.FooterLogo />
        </div>
        <div className="company-address">
          <div className="name-short">Bregant d.o.o.</div>
          <div className="name-long">Bregant digitalne integracije d.o.o.</div>
          <div className="address">Gubčeva cesta 11a, 8230 Mokronog, Slovenija</div>
        </div>
      </div>
      <div>
        <div className="id-number">
          Matična številka: <strong>8353158000</strong>
        </div>
        <div className="vat-number">
          Davčna številka: <strong>SI 27718492</strong>
        </div>
        <div className="e-mail">
          E-pošta:{' '}
          <strong>
            <a href="mailto:info@bregant.si">info@bregant.si</a>
          </strong>
        </div>
      </div>
    </SC.Footer>
  )
}

export default Footer

import { createGlobalStyle } from 'styled-components'

export const IdentityTheme = {
  identityRed: '#DF4C3A',
  identityGray: '#717274',
  contentWidth: 960
}

export const GlobalStyle = createGlobalStyle`
  html, body, #root {
    height: 100%;
  }
  body {
    padding: 0;
    margin: 0;
    font-family: 'Saira Semi Condensed', sans-serif;
    font-size: 16px;
  }
  a {
    color: ${IdentityTheme.identityGray};
  }
`

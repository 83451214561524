import * as SC from './LandingHero.styles'

const LandingHero = () => {
  return (
    <SC.LandingHero>
      <SC.LandingHeroLogo />
    </SC.LandingHero>
  )
}

export default LandingHero

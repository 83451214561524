import styled from 'styled-components'
import { IdentityTheme } from '../App.styles'

export const FooterBreakpoint = 700

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 32px;
  color: #717274;
  font-size: 15px;
  line-height: 20px;
  max-width: ${IdentityTheme.contentWidth}px;
  margin: 0 auto;
  @media (min-width: ${FooterBreakpoint}px) {
    flex-direction: row;
    align-items: flex-end;
  }
  > div {
    :after {
      content: '';
      clear: both;
      display: table;
    }
  }
  @media (min-width: ${FooterBreakpoint}px) {
    .company-logo {
      display: inline-block;
    }
    .company-address {
      display: inline-block;
      margin-left: 30px;
    }
  }
  .name-short {
    font-size: 22px;
    font-weight: bold;
    line-height: 32px;
  }
`

export const FooterLogo = styled.div`
  background-image: url('svg/bregant-logo-footer.svg');
  background-size: contain;
  width: 100px;
  height: 70px;
  display: block;
  margin-bottom: 8px;
  @media (min-width: ${FooterBreakpoint}px) {
    margin-bottom: 0;
  }
`

import { Fragment } from 'react'
import Footer from './footer/Footer'
import LandingHero from './landing-hero/LandingHero'
import { GlobalStyle } from './App.styles'

const App = () => {
  return (
    <Fragment>
      <GlobalStyle />
      <LandingHero />
      <Footer />
    </Fragment>
  )
}

export default App

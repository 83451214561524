import styled from 'styled-components'
import { IdentityTheme } from '../App.styles'

export const LandingHero = styled.div`
  display: block;
  background-color: ${IdentityTheme.identityRed};
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 250px;
`

export const LandingHeroLogo = styled.div`
  background-image: url('svg/bregant-logo-hero.svg');
  background-size: contain;
  border: none;
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -100px 0 0 -60px;
  width: 120px;
  height: 134px;
  @media (min-width: 900px) {
    margin: -200px 0 0 -150px;
    width: 300px;
    height: 334px;
  }
`
